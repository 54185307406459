@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-family: inherit;
    font-weight: bold;
    line-height: 1;
    color: inherit;
    text-rendering: optimizelegibility;
  }

  .subPageContentWrapper h1 {
    font-size: 1.8rem;
  }

  .subPageContentWrapper {
    margin: 80px 20px;
    width: 90%;
    font-family: "Helvetica", arial, sans-serif;
    line-height: 1.6em;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    color: #858585;
  }

  /* .subPageContentWrapper img {
    width: 100%;
    float: left;
    margin: 5px 25px 10px 0;
  } */

  .img-polaroid {
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper {
    margin: 50px 0 0 80px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }

  /* .subPageContentWrapper img {
    width: auto;
    float: left;
    margin: 5px 25px 10px 0;
  } */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}
