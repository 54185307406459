/******* MEDIA QUERIES ***********************************************/

@import url("https://fonts.googleapis.com/css?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css?family=Oswald&display=swap");

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .navbar {
    background-color: rgb(248, 249, 250, 0.9) !important;
  }

  #nav-text {
    color: #858585;
    font-family: "Varela Round";
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    overflow: scroll;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 2rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    /* border-radius: .25rem; */
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 0);
  }

  .ig-icon {
    margin-top: -6px;
    margin-left: 5px;
    color: #858585;
  }

  .at-icon {
    margin-top: -6px;
    color: #858585;
  }

  .menu-bars-icon {
    margin-top: -5px;
    /* color: #858585; */
    color: rgba(0, 0, 0, 1);
  }

  .navbar-brand {
    font-family: Oswald, Helvetica, arial, sans-serif;
    font-size: 28px;
  }

  /* .main-nav ul li a,
  .sub-nav ul li a {
    color: #858585;
    font-family: "Varela Round";
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    padding: 13px 0 13px 0;
    line-height: 35px;
  }

  .logo a {
    text-decoration: none;
    background-color: transparent;
    color: white;
    display: inline-block;
    font-family: Oswald, Helvetica, arial, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: normal;
    height: 55px;
    line-height: 55px;
    margin: 0;
    text-transform: uppercase;
    width: 350px;
  } */
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}
