/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .subPageContentWrapper.aboutPage {
  }
  .subPageContentWrapper.aboutPage img {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper.aboutPage {
    margin: 50px 0 0 80px;
    width: 70%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.aboutPage img {
    width: auto;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}
