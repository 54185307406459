@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-family: inherit;
    font-weight: bold;
    line-height: 1;
    color: inherit;
    text-rendering: optimizelegibility;
  }

  .subPageContentWrapper h1 {
    font-size: 1.8rem;
  }

  .subPageContentWrapper {
    margin: 80px 20px;
    width: 90%;
    font-family: "Helvetica", arial, sans-serif;
    line-height: 1.6em;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    color: #858585;
  }

  /* .subPageContentWrapper img {
    width: 100%;
    float: left;
    margin: 5px 25px 10px 0;
  } */

  .img-polaroid {
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper {
    margin: 50px 0 0 80px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }

  /* .subPageContentWrapper img {
    width: auto;
    float: left;
    margin: 5px 25px 10px 0;
  } */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .navBanner {
    background-color: rgba(17, 17, 17, 0.699219);
    display: block;
    margin: 0;
    position: absolute;
    bottom: 70px;
    width: 100%;
    z-index: 999;
    padding: 30px 30px;
  }

  .navBanner.subPage {
    position: static;
    background-color: rgba(17, 17, 17, 0);
    top: 0px;
    height: 200px;
    padding: 10px 30px 30px 30px;
  }

  .main-nav {
    margin-bottom: 25px;
  }

  .main-nav ul,
  .sub-nav ul {
    padding: 0;
  }

  .main-nav ul li,
  .sub-nav ul li {
    list-style: none outside none;
    position: relative;
    float: left;
    margin-right: 30px;
  }

  .sub-nav {
    width: 60%;
  }

  .main-nav ul li a,
  .sub-nav ul li a {
    color: #858585;
    font-family: "Varela Round";
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    padding: 13px 0 13px 0;
    line-height: 35px;
  }
  h1.logo {
    margin-top: 20px;
  }
  .logo a {
    text-decoration: none;
    background-color: transparent;
    color: white;
    display: inline-block;
    font-family: Oswald, Helvetica, arial, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: normal;
    height: 55px;
    line-height: 55px;
    margin: 0;
    text-transform: uppercase;
    width: 350px;
  }

  .navBanner.subPage .logo a {
    color: #444;
  }

  .navBanner li.selected a {
    color: white;
    cursor: default;
  }

  .navBanner.subPage li.selected a {
    color: black;
    cursor: default;
  }

  .navBanner a:hover {
    color: white;
    border: none;
  }

  .navBanner.subPage a:hover {
    color: black;
    border: none;
  }

  li.selected a::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 3px;
    height: 1px;
    background-color: #aaa;
    width: 50%;
    margin-left: -25%;
    left: 50%;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .navbar {
    background-color: rgb(248, 249, 250, 0.9) !important;
  }

  #nav-text {
    color: #858585;
    font-family: "Varela Round";
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    overflow: scroll;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 2rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    /* border-radius: .25rem; */
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 0);
  }

  .ig-icon {
    margin-top: -6px;
    margin-left: 5px;
    color: #858585;
  }

  .at-icon {
    margin-top: -6px;
    color: #858585;
  }

  .menu-bars-icon {
    margin-top: -5px;
    /* color: #858585; */
    color: rgba(0, 0, 0, 1);
  }

  .navbar-brand {
    font-family: Oswald, Helvetica, arial, sans-serif;
    font-size: 28px;
  }

  /* .main-nav ul li a,
  .sub-nav ul li a {
    color: #858585;
    font-family: "Varela Round";
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    padding: 13px 0 13px 0;
    line-height: 35px;
  }

  .logo a {
    text-decoration: none;
    background-color: transparent;
    color: white;
    display: inline-block;
    font-family: Oswald, Helvetica, arial, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: normal;
    height: 55px;
    line-height: 55px;
    margin: 0;
    text-transform: uppercase;
    width: 350px;
  } */
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .subPageContentWrapper.aboutPage {
  }
  .subPageContentWrapper.aboutPage img {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper.aboutPage {
    margin: 50px 0 0 80px;
    width: 70%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.aboutPage img {
    width: auto;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .subPageContentWrapper.galleryPage {
    width: 100%;
    margin: 80px 0px;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper.galleryPage {
    margin: 50px 0 0 80px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  h1.press-headline {
    font-size: 1.5rem;
    color: #6b6b6b;
    font-weight: 700;
  }

  h1.press-headline:hover {
    color: black;
    border: none;
  }

  div.row a {
    text-decoration: none;
  }

  .press-image {
    margin-bottom: 30px;
  }

  .pressItem {
    margin-bottom: 40px;
  }

  .subPageContentWrapper.pressPage img {
    width: 100%;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  h1.press-headline {
    font-size: 1.8rem;
    color: #6b6b6b;
    font-weight: 700;
  }
  .subPageContentWrapper.pressPage img {
    width: 70%;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .contactPage a {
    color: #858585;
  }
  .contactPage a:hover {
    color: #444;
  }

  .subPageContentWrapper.contactPage {
    margin: 90px 0 0 30px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.contactPage h4 {
    color: #858585;
    font-family: "Varela Round";
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    /* margin-top: 20px;
    padding: 0px 0 40px 0; */
    /* line-height: 15px; */
  }

  .subPageContentWrapper.contactPage .mobile-icon {
    margin: 0 20px 0px 0;
  }

  .subPageContentWrapper.contactPage .mail-icon {
    margin: 0 20px 0px 0;
  }

  .subPageContentWrapper.contactPage .ig-icon {
    margin: 0 20px 0px 0;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper.contactPage {
    margin: 80px 0 0 80px;
    width: 70%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.contactPage img {
    width: auto;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-family: inherit;
    font-weight: bold;
    line-height: 1;
    color: inherit;
    text-rendering: optimizelegibility;
  }

  .subPageContentWrapper.menuPage {
    width: 90%;
    font-family: "Helvetica", arial, sans-serif;
  }

  .subPageContentWrapper.menuPage img {
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 24px;
    line-height: 40px;
  }

  .menuBody p {
    margin-top: -15px;
  }

  .menuBody h2 {
    margin-top: 40px;
  }

  .subPageContentWrapper.menuPage h1 {
    font-size: 1.8rem;
    color: #6b6b6b;
    font-weight: 700;
  }

  .subPageContentWrapper.menuPage h2 {
    font-size: 1.5rem;
    color: #6b6b6b;
    font-weight: 700;
  }

  .subPageContentWrapper.menuPage h3 {
    font-size: 1rem;
    color: #6b6b6b;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .subPageContentWrapper.menuPage {
    margin: 50px 0 0 80px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.menuPage img {
    width: 60%;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}

