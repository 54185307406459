/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-family: inherit;
    font-weight: bold;
    line-height: 1;
    color: inherit;
    text-rendering: optimizelegibility;
  }

  .subPageContentWrapper.menuPage {
    width: 90%;
    font-family: "Helvetica", arial, sans-serif;
  }

  .subPageContentWrapper.menuPage img {
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 24px;
    line-height: 40px;
  }

  .menuBody p {
    margin-top: -15px;
  }

  .menuBody h2 {
    margin-top: 40px;
  }

  .subPageContentWrapper.menuPage h1 {
    font-size: 1.8rem;
    color: #6b6b6b;
    font-weight: 700;
  }

  .subPageContentWrapper.menuPage h2 {
    font-size: 1.5rem;
    color: #6b6b6b;
    font-weight: 700;
  }

  .subPageContentWrapper.menuPage h3 {
    font-size: 1rem;
    color: #6b6b6b;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .subPageContentWrapper.menuPage {
    margin: 50px 0 0 80px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.menuPage img {
    width: 60%;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}
