/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .contactPage a {
    color: #858585;
  }
  .contactPage a:hover {
    color: #444;
  }

  .subPageContentWrapper.contactPage {
    margin: 90px 0 0 30px;
    width: 80%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.contactPage h4 {
    color: #858585;
    font-family: "Varela Round";
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    /* margin-top: 20px;
    padding: 0px 0 40px 0; */
    /* line-height: 15px; */
  }

  .subPageContentWrapper.contactPage .mobile-icon {
    margin: 0 20px 0px 0;
  }

  .subPageContentWrapper.contactPage .mail-icon {
    margin: 0 20px 0px 0;
  }

  .subPageContentWrapper.contactPage .ig-icon {
    margin: 0 20px 0px 0;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .subPageContentWrapper.contactPage {
    margin: 80px 0 0 80px;
    width: 70%;
    font-size: 16px;
    color: #858585;
  }

  .subPageContentWrapper.contactPage img {
    width: auto;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}
