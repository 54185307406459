/******* MEDIA QUERIES ***********************************************/

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  h1.press-headline {
    font-size: 1.5rem;
    color: #6b6b6b;
    font-weight: 700;
  }

  h1.press-headline:hover {
    color: black;
    border: none;
  }

  div.row a {
    text-decoration: none;
  }

  .press-image {
    margin-bottom: 30px;
  }

  .pressItem {
    margin-bottom: 40px;
  }

  .subPageContentWrapper.pressPage img {
    width: 100%;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) and (orientation: landscape) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  h1.press-headline {
    font-size: 1.8rem;
    color: #6b6b6b;
    font-weight: 700;
  }
  .subPageContentWrapper.pressPage img {
    width: 70%;
    float: left;
    margin: 5px 25px 10px 0;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 1600px) {
  /* super-hi-res desktops */
}
